

























import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    ModalAccountInvite: () => import("@/components/accounts/modals/invite.vue"),
    Avatar: () => import("@/components/general/avatar.vue"),
  },
})
export default class PageAdminUsers extends Vue {
  @Getter("account/all") accounts!: Account[];

  open = false;
}
